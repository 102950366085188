exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-author-jsx": () => import("./../../../src/templates/blog/author.jsx" /* webpackChunkName: "component---src-templates-blog-author-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog/post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-document-jsx": () => import("./../../../src/templates/document.jsx" /* webpackChunkName: "component---src-templates-document-jsx" */),
  "component---src-templates-page-adw-jsx": () => import("./../../../src/templates/pageADW.jsx" /* webpackChunkName: "component---src-templates-page-adw-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-promotions-jsx": () => import("./../../../src/templates/promotions.jsx" /* webpackChunkName: "component---src-templates-promotions-jsx" */)
}

